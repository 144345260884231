import { render, staticRenderFns } from "./login.vue?vue&type=template&id=2806d9e6&"
import script from "./login.vue?vue&type=script&lang=ts&"
export * from "./login.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingOrverlay: require('/home/ar-admin/development/weddingbox-client/src/components/LoadingOrverlay.vue').default,ShopSetting: require('/home/ar-admin/development/weddingbox-client/src/components/ShopSetting.vue').default,TheLoginModal: require('/home/ar-admin/development/weddingbox-client/src/components/TheLoginModal.vue').default,AppIcon: require('/home/ar-admin/development/weddingbox-client/src/components/AppIcon.vue').default})
