

























import {
  defineComponent,
  onMounted,
  ref,
  useRouter,
  useStore,
} from '@nuxtjs/composition-api'
import TheLoginModal from '../components/TheLoginModal.vue'
import IconLoginImage from '~/components/icons/IconLoginImage.vue'
import { useAuth } from '@/composable/api/useAuth'
import { useInitializeState } from '~/composable/general/useInitializeState'
import SmsModal from '@/components/_graduationCeremony/molecules/modals/SmsModal.vue'
import DateTime from '~/utils/dateTime'

export default defineComponent({
  components: {
    TheLoginModal,
    IconLoginImage,
    SmsModal,
  },
  setup() {
    const isShopSetting = ref(false)
    const router = useRouter()
    const store = useStore()
    const shopName = ref(store.getters['SettingShop/getShop']?.name)
    const { checkSmsCode, login, logout } = useAuth()
    const { initAllDocumentStore, initCheckListStoreInfo } =
      useInitializeState()
    const isShowSmsModal = ref(false)
    const isSmsPinError = ref(false)
    const isLoading = ref(false)
    const user = ref<{
      email: string
      password: string
    } | null>(null)

    const handleBackLink = (shop?: string) => {
      if (shop) {
        shopName.value = shop
      }
      isShopSetting.value = false
    }

    const handleChangeShop = () => {
      isShopSetting.value = true
      shopName.value = ''
    }

    const setLoginDateAndTime = () => {
      store.commit('LoginUser/setLoginDateAndTime', {
        login_date: Number(new DateTime().toDateString().replaceAll('-', '')),
      })
    }

    // smsコードエラーのリセット
    const resetSmsPinError = () => {
      isSmsPinError.value = false
    }

    // smsモーダル開
    const openSmsModal = (payload: { email: string; password: string }) => {
      user.value = payload
      isShowSmsModal.value = true
    }

    // smsモーダル閉
    const closeSmsModal = () => {
      isSmsPinError.value = false
      isShowSmsModal.value = false
    }

    // ローディング切り替え
    const toggleLoading = () => {
      isLoading.value = !isLoading.value
    }

    // sms認証
    const smsAuth = async (code: string) => {
      toggleLoading()
      if (!user.value) {
        toggleLoading()
        return
      }

      // ①smsコードの確認
      try {
        const responseSmsCode = await checkSmsCode({ code })
        // NOTE: 入力したSMS値 とレスポンスの{"success":"SMS値"}が正しければ認証が通る
        if (responseSmsCode.data.success === code) {
          console.log('SMS code verification successful')
        } else if (responseSmsCode.data.success !== code) {
          throw new Error('入力された認証コード情報が一致しません。')
        }
      } catch (error) {
        isSmsPinError.value = true
        toggleLoading()
        return
      }

      // ②ログイン
      try {
        await login(user.value)
        setLoginDateAndTime()
        isShowSmsModal.value = false
        router.push('/')
      } catch (error) {
        console.error(error)
        toggleLoading()
        alert(
          '[ログインエラー] ログインに失敗しました。アカウント情報をご確認ください。'
        )
      }

      toggleLoading()
    }

    onMounted(() => {
      store.commit('LoginUser/delete')
      initAllDocumentStore()
      initCheckListStoreInfo()
      logout()
    })

    return {
      isShopSetting,
      shopName,
      handleChangeShop,
      handleBackLink,
      isShowSmsModal,
      isSmsPinError,
      openSmsModal,
      closeSmsModal,
      smsAuth,
      resetSmsPinError,
      isLoading,
      toggleLoading,
    }
  },
})
