












































import { computed, defineComponent, ref, watch } from '@nuxtjs/composition-api'
import AppModalWindow from 'storybook/components/atoms/AppModalWindow.vue'
import AppPinkButton from 'storybook/components/atoms/AppPinkButton.vue'
import AppTextButton from 'storybook/components/atoms/AppTextButton.vue'

export default defineComponent({
  components: {
    AppModalWindow,
    AppPinkButton,
    AppTextButton,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isPhoto: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const smsCode = ref('')
    const isFocus = ref(false)

    // AutoFocus用
    const pin = ref<HTMLInputElement | null>(null)

    // 認証ボタン可否
    const isValid = computed(() => {
      return smsCode.value.length === 6
    })

    // 認証ボタン押下
    const clickExecuteButton = () => {
      emit('clickExecuteButton', smsCode.value)
      smsCode.value = ''
    }

    // エンターキー押下
    const keydownEnter = () => {
      if (isValid.value) {
        emit('keydownEnter', smsCode.value)
        smsCode.value = ''
      }
    }

    // 戻るボタン押下
    const clickCancelButton = () => {
      emit('clickCancelButton')
    }

    // フォーカス時にカーソルを右端へ移動
    const changeFocusPosition = () => {
      pin.value?.focus()
      pin.value?.setSelectionRange(smsCode.value.length, smsCode.value.length)
    }

    watch(smsCode, () => {
      if (!smsCode.value.match(/^[0-9]+$/)) {
        smsCode.value = smsCode.value.slice(0, -1)
      } else {
        // 親コンポーネントのエラー判定をfalseにするためのフックとして必要
        emit('handleChangeInput')
      }
    })

    return {
      pin,
      isValid,
      clickExecuteButton,
      clickCancelButton,
      keydownEnter,
      smsCode,
      isFocus,
      changeFocusPosition,
    }
  },
})
