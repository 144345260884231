
























import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  data() {
    return {
      width: 1551.813,
      height: 967.471,
    }
  },
})
