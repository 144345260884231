

















































import {
  defineComponent,
  ref,
  computed,
  useStore,
  onMounted,
  watch,
} from '@nuxtjs/composition-api'
import { Store } from 'vuex'
import { useMasterData } from '@/composable/api/useMasterData'
import { Shop } from '~/api/masterRepository'
import IconArrowLeft from '~/components/icons/IconArrowLeft.vue'

export default defineComponent({
  emits: ['historyBack'],
  components: { IconArrowLeft },
  setup(_, { emit }) {
    const { fetchShopList, shopList } = useMasterData()
    const store = useStore() as Store<any>
    const inputValue = ref('')
    const selectedShop = ref<Shop[]>([])
    const isEmpty = ref(true)
    const isFocused = ref(false)
    const isDisplayedInputForm = ref(true)

    // リストから選択した時に表示される
    const FilteredShopList = computed(() => {
      const filteredTags = []
      for (const i in shopList.value) {
        const tag: any = shopList.value[i]
        if (String(tag.name).includes(inputValue.value)) {
          filteredTags.push(tag)
        }
      }
      return filteredTags
    })

    const handleBackLink = () => emit('handleBackLink')

    // フォーカスでサジェストを表示
    const onFocus = () => (isFocused.value = true)

    // inputタグに入力した値を取得してリアクティブな値inputValueに格納する
    const onInput = (event: Event) => {
      const { target } = event
      if (target instanceof HTMLInputElement) {
        inputValue.value = target.value
      }
    }
    // 配列から削除・inputフォームを出現させ、要素リセットする
    const remove = (index: number) => {
      selectedShop.value.splice(index, 1)
      isDisplayedInputForm.value = true
      inputValue.value = ''
    }
    // 配列に追加する・inputフォームを非表示にする
    const add = (item: Shop) => {
      if (selectedShop.value.length === 0) {
        selectedShop.value.push(item)
        isDisplayedInputForm.value = false
      } else if (selectedShop.value.length === 1) {
        selectedShop.value.splice(0, 1)
        selectedShop.value.push(item)
      }
      isFocused.value = false
    }

    // 店舗を決定し、storeの情報を変更
    const clickDecision = (item: Shop[]) => {
      if (!inputValue.value && selectedShop.value.length === 0) return

      if (item[0]?.name) {
        store.dispatch('SettingShop/setShop', item[0])
        alert(`${item[0].name}に変更されました。`)
        emit('handleBackLink', item[0].name)
        return
      }

      alert('正しい店舗を選択してください。')
      return
    }

    // 店舗マスタ取得
    onMounted(fetchShopList)

    return {
      remove,
      FilteredShopList,
      onInput,
      inputValue,
      add,
      selectedShop,
      isEmpty,
      isFocused,
      onFocus,
      isDisplayedInputForm,
      clickDecision,
      handleBackLink,
    }
  },
})
